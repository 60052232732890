import broadExposureSrc from 'img/broadExposure.webp'
import easyManagementSrc from 'img/easyManagement.webp'
import consistentEarningsSrc from 'img/consistentEarnings.webp'

export const JOIN_US = 'Join our car rental marketplace'
export const BUTTON_LABEL = 'Get started - it’s free!'

export const ADVANTAGES = [
  {
    count: '2M',
    label: 'Broad exposure',
    description:
      'Access a wide audience of customers actively seeking rental cars, boosting your visibility and reach.',
    icon: broadExposureSrc,
  },
  {
    count: '1.6K',
    label: 'Easy management',
    description:
      'List your vehicles and handle bookings effortlessly on our user-friendly platform.',
    icon: easyManagementSrc,
  },
  {
    count: '500K',
    label: 'Consistent earnings',
    description:
      'Receive reliable and prompt payments after services, supporting steady business growth.',
    icon: consistentEarningsSrc,
  },
]
