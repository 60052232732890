import {
  Typography,
  OriginButton,
  StatusBadge,
  LazyImage,
} from '@frontend/design_system'
import {
  useDetectScreenOnClient,
  BREAKPOINTS_KEYS,
} from '@frontend/design_system/src/designSystem/hooks'
import { ADVANTAGES, BUTTON_LABEL, JOIN_US } from 'texts/joinBlock'
import { JoinBlockProps } from './JoinBlock.types'
import styles from './index.module.scss'

export const JoinBlock = ({ onClick }: JoinBlockProps) => {
  const screen = useDetectScreenOnClient()

  return (
    <>
      <div className={styles.main} data-testid="join-block">
        <div className={styles['left-block']}>
          <Typography name="h1WBold" nameForTablet="h3WBold" Tag="h1">
            {JOIN_US}
          </Typography>
          <div className={styles.advantages}>
            {ADVANTAGES.map(({ count, label }) => (
              <div className={styles.advantage} key={label}>
                <StatusBadge
                  label={count}
                  type="ok"
                  typographyName="h4WExtraBold"
                />
                <Typography name="titleWMedium" nameForTablet="subtitleWMedium">
                  {label}
                </Typography>
              </div>
            ))}
          </div>
          {screen !== BREAKPOINTS_KEYS.MOBILE && (
            <div className={styles['button-wrapper']}>
              <OriginButton
                label={BUTTON_LABEL}
                size="gigantic"
                onClick={onClick}
                typographyName="body1WBold"
              />
            </div>
          )}
        </div>
        <div className={styles['video-wrapper']}>
          <video autoPlay loop muted playsInline className={styles.video}>
            <source
              src={`${process.env.REACT_APP_AWS_CDN_VIDEO_PATH}/join.mp4`}
              type="video/mp4"
            />
          </video>
        </div>
        {screen === BREAKPOINTS_KEYS.MOBILE && (
          <div className={styles['button-wrapper']}>
            <OriginButton
              label={BUTTON_LABEL}
              size="gigantic"
              onClick={onClick}
              typographyName="body1WBold"
              dataTestId="get-started-button-mobile"
            />
          </div>
        )}
      </div>
      <div className={styles['advantage-cards']}>
        {ADVANTAGES.map(({ icon, label, description }) => (
          <div className={styles['advantage-card']} key={label}>
            <LazyImage
              src={icon}
              width={96}
              height={96}
              className={styles['card-image']}
            />
            <Typography
              name="titleWBold"
              nameForTablet="subtitleWBold"
              className={styles['advantage-title']}
            >
              {label}
            </Typography>
            <Typography name="body1WMedium" color="grey500">
              {description}
            </Typography>
          </div>
        ))}
      </div>
    </>
  )
}
